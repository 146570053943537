import { Spinner } from '@components/index';

interface Props {
  color?: string;
  children: any;
  isLoaded: boolean;
}

export const SpinnerHoc = (props: Props) => {
  const { color = 'bg-yellow-1', children, isLoaded = false } = props;

  return isLoaded ? children : <Spinner color={color} />;
};
